import { Stack } from '@mui/material';
import NextImage from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import StrapiImage from '@/components/StrapiImage/StrapiImage';

import { Partner, Wrapper } from './style';

/**
 * PartnersSection.
 */
const PartnersSection = ({
  className,
  partnerClassName,
  logos,
  imageComponentType,
}) => {
  return (
    <Wrapper className={className}>
      <Stack
        component="ul"
        direction="row"
        alignItems="space-around"
        justifyContent="center"
        flexWrap="wrap"
        p={1}
        m={0}>
        {logos.map(partner => (
          <Partner key={partner.id} className={partnerClassName}>
            {imageComponentType === 'next' ? (
              <NextImage
                src={partner.attributes.url}
                alt={partner.attributes.alternativeText}
                fill
                sizes="140px"
              />
            ) : (
              <StrapiImage data={partner} fill sizes="140px" />
            )}
          </Partner>
        ))}
      </Stack>
    </Wrapper>
  );
};

PartnersSection.propTypes = {
  className: PropTypes.string,
  partnerClassName: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        alternativeText: PropTypes.string,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  imageComponentType: PropTypes.oneOf(['strapi', 'next']),
};

PartnersSection.defaultProps = {
  className: '',
  partnerClassName: '',
  imageComponentType: 'strapi',
};

export default PartnersSection;
